// https://api.openweathermap.org/data/2.5/weather?q=pune&appid=b642574bcf1a0017312a0481e5e2c55e 
import React,{useEffect, useState} from 'react'
import './temp.css'


const Temp = () => {
    const [searchvalue,setsearchvalue] = useState("Nagpur");
    const [tempInfo,settempInfo] = useState({})
    const [weather,setweather]=useState("")
    const getweatherinfo = async()=>{
  try{
    let url = `https://api.openweathermap.org/data/2.5/weather?q=${searchvalue}&units=metric&appid=b642574bcf1a0017312a0481e5e2c55e `;
    const res =  await fetch(url);
    const data = await res.json()

    const {temp,humidity,pressure} = data.main;
    const {main:weathermood} = data.weather[0];
    const {name} = data;
    const{speed} = data.wind;
    const{country,sunset} = data.sys;
    // converting he seconds inot time 
    let sec  = tempInfo.sunset;
    let date = new Date(sec * 1000);
    let timestr = `${date.getHours()}:${date.getMinutes()}`

    const myNewInfo = {
        temp,humidity,pressure,weathermood,name,speed,country,sunset ,timestr
    }

    
    settempInfo(myNewInfo)

    console.log(data)
  }catch(error){
console.log(error)
  }
    }
   useEffect(()=>{
    getweatherinfo()

   },[])
   useEffect(()=>{
    if(tempInfo.weathermood){
        switch(tempInfo.weathermood){
            case "Clouds": setweather("wi-day-cloudy");
                break;
            case "Haze": setweather("wi-fog");
                break;
            case "Clear": setweather("wi-day-sunny");
                break;
           default:
            setweather("wi-day-sunny");
            break;
                
        }
    }
   },[tempInfo.weathermood])
    

  return (
   <div div className='background'>
   <div className="wrap container">
    <div className="search row">
     <input type="search" placeholder='Search' autoFocus id="Search" className='searchTerm col-2' value={searchvalue} onChange={(e)=>setsearchvalue(e.target.value)} />
     <button className='searchButton col-2'  type="button" onClick={getweatherinfo}>Search</button>
    </div>
   </div>

   {/* our temp card */}
   <div className="widget">
    <div className="iconwrapper">
        <i className={`wi ${weather} weatherIcon`}></i>
    </div>
    <div className="row-2">
    <div className="weatherInfo">
        <div className="">
            <span className=' temperature'>{tempInfo.temp}&#176;</span>
        </div>
        <div className="description">
            <div className="weatherCondition">
                {tempInfo.weathermood}
            </div>
            <div className="place">{tempInfo.name},{tempInfo.country}</div>
        </div>
        </div>
        <div className="date">{new Date().toLocaleString()}</div>
    </div>
    
        {/* Our 4 column section  */}
        <div className="extra-temperature">
           
                <div className="twosided">
                  
                        <p><i className={"wi wi-sunset left1"}></i></p>
                        <p className='right1'>{tempInfo.timestr} <br />Sunset</p>
                    
                </div>
                <div className="twosided">
                  
                        <p><i className={"wi wi-humidity left1"}></i></p>
                        <p className='right1'>{tempInfo.humidity} <br />Humidity</p>
                    
                </div>
                <div className="twosided">
                  
                        <p><i className={"wi wi-rain left1"}></i></p>
                        <p className='right1'>{tempInfo.pressure} <br />Pressure</p>
                    
                </div>
                <div className="twosided">
                  
                        <p><i className={"wi wi-strong-wind left1"}></i></p>
                        <p className='right1'>{tempInfo.speed} <br />Speed</p>
                    
                </div>
            
        </div>
    
   </div>
   </div>
  )
}

export default Temp