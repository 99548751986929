import { useState } from 'react';
import Temp from './components/weather/temp';



import './App.css'


function App() {
   
  return (
    <div className="container">
      <Temp/>
    </div>
    
  );
}

export default App;